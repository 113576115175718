@use "../variables" as *;

.hypecast-primary-button {
  font-family: $font-family-bold;
  color: white;
  // font-size: 20px;
  line-height: 100%;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;

  cursor: pointer;
  border-radius: 4px;
  position: relative;
  background-image: linear-gradient(45deg, rgba(0, 209, 255, 1) 0%, rgba(0, 41, 255, 1) 100%);
  z-index: 1;
}

.hypecast-primary-button::before {
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(45deg, rgba(0, 41, 255, 1) 0%, rgba(0, 209, 255, 1) 100%);
  z-index: -1;
  transition: opacity 0.3s linear;
  opacity: 0;
}

.hypecast-primary-button:hover::before {
  opacity: 1;
}

button.disabled.hypecast-primary-button {
  background-image: none;
  background: gray;
  color: $font-color-sub;
  cursor: pointer;

  &:hover {
    background: gray;
    cursor: pointer;
  }
}

button.disabled.hypecast-primary-button::before {
  background-image: none;
  cursor: pointer;
}
