.hypecast-table .mat-header-row {
    background-color: #090909;
}
.hypecast-table .mat-header-row .mat-header-cell {
    color: white;
    font-size: 1rem;
    line-height: 1.5rem;
}
.hypecast-table .mat-sort-header-arrow {
    color: white !important;
}
.hypecast-table .mat-cell {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #090909;
    white-space: nowrap;
    border-color: #090909;
    padding-top: 10px !important;
    padding-bottom: 10px;
}
.hypecast-table .mat-cell div {
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
}
.hypecast-table .mat-row {
    height: 75px !important;
}
.hypecast-table .mat-row:hover .mat-cell {
    background-color: white;
}
