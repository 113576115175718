@use "../variables" as *;

.mat-select-panel {
  background-color: #fff;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;

  .mat-option {
    &.list-item.list-item--selected {
      background-color: rgba(0, 0, 0, 0.12);

      .list-item__primary-text {
        color: $font-color-secondary-blue;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}
