@use "../variables" as *;

.hypecast-secondary-button {
    font-family: $font-family-bold;
    color: '#090909';
    // font-size: 20px;
    line-height: 100%;
    white-space: nowrap;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;

    cursor: pointer;

    border: 2px solid #090909;
    box-sizing: border-box;
    border-radius: 4px;

    position: relative;
    transition: opacity 0.3s linear;

  &.hypecast-secondary-button-grey {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
}

.hypecast-secondary-button:hover {
    border-color: #0029FF;
}
