:root {
  // Dialog overrides
  --dialog-supporting-text-font: 'Spartan-Regular', 'Helvetica Neue', sans-serif;
  --dialog-supporting-text-weight: 400;
  --dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
  --dialog-supporting-text-tracking: normal;
}

.dialog mat-dialog-content.dialog__content {
  padding-top: 24px;
  padding-bottom: 24px;
}
