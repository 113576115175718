@use "../variables.scss"  as *;

.hypecast-form-field {
  .text-field {
    padding: 0;
  }

  .mat-form-field-infix {
    padding-top: 24px;
  }

  // focused non-errored field bottom border
  &.mat-focused {
    .line-ripple.line-ripple--active {
      &::before {
        border-color: #0029FF;
        border-bottom-width: 2px;
      }
    }
  }

  .text-field--filled:not(.text-field--invalid) {
    .line-ripple {
      &::after {
        transform: scaleX(1);
        opacity: 0;
        border-bottom-color: #0029FF;
      }
    }
  }

  // end: focused non-errored field bottom border

  // errored input field bottom border
  &.mat-form-field-invalid {
    .text-field--filled.text-field--invalid {
      .line-ripple {
        &::before {
          border-color: #f44336;
          border-bottom-width: 2px;
        }

        &::after {
          transform: scaleX(1);
          opacity: 0;
          border-bottom-color: #f44336;
        }
      }
    }
  }

  // end: errored input field bottom border

  // mat-form-field - css classes hierarchy to mat-error validation error message
  &.mat-form-field {
    .mat-form-field-subscript-wrapper.mat-form-field-bottom-align {
      .mat-form-field-error-wrapper {
        padding: 0;

        .mat-form-field-error.mat-form-field-bottom-align {
          color: #f44336;
          font-size: 10.5px;
        }
      }
    }
  }
}
